import ScrollTrigger from 'gsap/ScrollTrigger';
import {boundMethod} from "autobind-decorator";
import {gsap} from 'gsap'
import {IntersectionController} from "stimulus-use";

export default class extends IntersectionController {

  _horizontal = 0;
  _horizontalScrollTrigger = false;

  static values = {
    scrub: Number,
    speed: String,
    element: String,
    start: String,
    end: String,
    markers: Boolean,
  }

  static options = {
    threshold: 0,
  }

  get isTouch() {
    return 'ontouchstart' in document.documentElement;
  }

  get elementToAnimate() {
    if (window.innerWidth < 868) return false;
    return this.element.querySelector(this.elementValue || null) || this.element;
  }

  connect() {
    gsap.registerPlugin(ScrollTrigger);
    this.horizontalScrollTrigger = window.innerWidth;
    window.addEventListener('resize', this.onResize);
    if(this.element.dataset.bg){
      this.element.style.backgroundImage = 'url('+this.element.dataset.bg+')';
    }
  }



  set horizontalScrollTrigger(value){
    if(this._horizontal >= 868 && value < 868 ){
      this._horizontal = value;
      this._horizontalScrollTrigger = false;
    }

    if(this._horizontal < 868 && value >= 868 ){
      this._horizontal = value;
      this._horizontalScrollTrigger = true;
      this.animate();
    }
  }
  get horizontalScrollTrigger(){
    return this._horizontalScrollTrigger;
  }

  @boundMethod
  onResize(){
    this.horizontalScrollTrigger = window.innerWidth;
  }


  @boundMethod
  appear() {
    if (!this.element.closest('.popin') || this.element.closest('.popin.popin-moved') ) {
      this.unobserve();
    }
  }

  @boundMethod
  animate() {
    if(!this.elementToAnimate) return;
    this.gsap =
      gsap.to(this.elementToAnimate, {
        x: (i, target) => {
          if (!this.elementToAnimate) return 0;
          return this.elementToAnimate.offsetWidth * (this.speedValue || -.5)
        },
        scrollTrigger: {
          id: 'parallax',
          trigger: this.elementToAnimate,
          invalidateOnRefresh: true,
          start: this.startValue || "top bottom",
          end: this.endValue || "bottom+=150 top",
          scrub: 0,
          markers: this.markersValue || null,
        },
        ease: "none"
      });

    // ONLY if markers
    if (
      window.bodyScrollBar
      && document.querySelector('.gsap-marker-scroller-start')
    ) {
      const markers = gsap.utils.toArray('[class *= "gsap-marker"]');
      window.bodyScrollBar.addListener(({offset}) => {
        gsap.set(markers, {marginLeft: -offset.x})
      });
    }
  }

  disconnect() {
    if(this.gsap){
      gsap.killTweensOf(this.elementToAnimate);
      this.gsap.kill();
      this.gsap = null;
    }

    super.disconnect();
  }

}
