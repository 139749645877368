import {IntersectionController} from "stimulus-use";
import {boundMethod} from "autobind-decorator";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

export default class extends IntersectionController {

  initialize() {
    super.initialize();
    this._horizontal = undefined;
  }

  connect() {
    super.connect();
    gsap.registerPlugin(ScrollTrigger);
    this.setTweenMonTest();
    window.addEventListener('resize', this.onResize);
  }

  set horizontalScrollTrigger(value){
    if(value !== this._horizontal && value || value !== this._horizontal && !value){
        this._horizontal = value;
        this.setTweenMonTest();
    }
  }
  get horizontalScrollTrigger(){
    return this._horizontal;
  }

  @boundMethod
  onResize(){
    this.horizontalScrollTrigger = (window.innerWidth >= 868);
  }

  @boundMethod
  appear() {
    this.element.dispatchEvent(new CustomEvent("appear:el"));
    if (!this.element.closest('.popin') || this.element.closest('.popin.popin-moved') ) {
      this.unobserve();
    }
  }

  @boundMethod
  setTweenMonTest() {
    this.scrollTween = gsap.to(this.element, {
      id:'MonTest',
      opacity:1,
      x: 100,
      scrollTrigger: {
        trigger: this.element,
        start: () => (window.innerWidth >= 1024) ? `-=100 +=${innerWidth}` : `start +=${innerHeight}`,
        end: `start+=${this.element.getBoundingClientRect().width} start`,
        scrub: true,
        markers: false,
      }
    });

    // ONLY if markers
    if (
      window.bodyScrollBar
      && document.querySelector('.gsap-marker-scroller-start')
    ) {
      const markers = gsap.utils.toArray('[class *= "gsap-marker"]');
      window.bodyScrollBar.addListener(({offset}) => {
        gsap.set(markers, {marginLeft: -offset.x})
      });
    }

  }


}
