import { boundMethod } from 'autobind-decorator';
import { Controller } from '@hotwired/stimulus';
import gsap from 'gsap';
import GoogleMapsApi from '../../js/libs/GoogleMapsApi';

export default class {
  constructor ( map,element,store) {
    this.map = map;
    this.element = element.querySelector(`[data-store-id="${store.id}"]`);
    /*this.markerList = this.element.querySelector(`.uielement-storelocator-marker`);*/
    this.store = store;
    this.marker = new google.maps.Marker({
      position: new google.maps.LatLng(store.lat, store.lon),
      map: this.map,
      title: store.name,
      index: store.id,
      // icon: this.pinSymbol(store.color),
      icon: store.svg,
     /* icon: {
        path: google.maps.SymbolPath.CIRCLE,
        strokeColor: '#fff',
        fillColor: '#A2823B',
        strokeWeight: 1.0,
        fillOpacity: 1.0,
        scale: 20,
      },*/
    });
  /*  this.infoWindow = new google.maps.InfoWindow({
      content: `<div class="uielement-storelocator-store-info-window p-4">${this.element.querySelector('[data-store-content]').innerHTML}</div>`,
    });*/

  //  this.marker.addListener('click',  this.onClickMapMarker);

  }

  disconnect(){
    google.maps.event.clearInstanceListeners(this.marker);
  }
  @boundMethod
  onClickListMarker(){
    this.infoWindow.open(this.map, this.marker);
  }

  @boundMethod
  onClickMapMarker(){
    this.infoWindow.open(this.map, this.marker);
  }

  pinSymbol (color) {

    return {
      path: 'M31.561,9.559A12.41,12.41,0,1,0,14.624,26.493a11.91,11.91,0,0,1,5.971,9.518.2.2,0,0,0,.2.184.2.2,0,0,0,.2-.184,11.911,11.911,0,0,1,5.969-9.518,12.405,12.405,0,0,0,4.6-16.935Z',
      fillColor: color,
      fillOpacity: 1,
      strokeWeight: 0,
      anchor: new google.maps.Point(15, 15),
    };
  };

}
