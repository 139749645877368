import {boundMethod} from 'autobind-decorator';
import {Controller} from '@hotwired/stimulus';
import {useWindowResize} from 'stimulus-use';
import GLightbox from 'glightbox';
import gsap from 'gsap';

export default class extends Controller {
  static targets = ['customLightboxHTML', 'customSlideHTML', 'bar', 'title', 'download', 'unDownloadableInfo', 'elementsContainer', 'items', 'galleryElt'];

  initialize() {
    //document.body.appendChild(this.galleryEltTarget);
    this.customLightboxHTML = this.customLightboxHTMLTarget.innerHTML;
    this.customSlideHTML = this.customSlideHTMLTarget.innerHTML;
    this.unDownloadableInfo = this.unDownloadableInfoTarget;
    this.customLightboxHTMLTarget.remove();
    this.customSlideHTMLTarget.remove();
    useWindowResize(this);
    gsap.set(this.barTarget, {y: '-100%'});
  }

  windowResize({width, height, event}) {
    this.fillLastLine()
  }

  fillLastLine() {
    [...this.element.querySelectorAll('.uielement-gallery-element-fake')].forEach(elt => elt.remove());
    let _grilleElt = (this.element.querySelectorAll('.uielement-gallery-element'));

    if (_grilleElt.length === 0) {
      return;
    }

    let _container = this.elementsContainerTarget;
    let _aLine = {};
    let _lastLine;
    let _maxTop = 0;
    [..._grilleElt].forEach((elt) => {
      let _top = parseInt(elt.getBoundingClientRect().top);
      _aLine[_top] = (_aLine[_top] || []);
      _aLine[_top].push(1);
      _maxTop = Math.max(_maxTop, _top);
    });
    _lastLine = _aLine[_maxTop];

    let _maxPerLine = 0;
    for (let _index in _aLine) {
      _maxPerLine = Math.max(_maxPerLine, _aLine[_index].length);
    }

    let _nbRequire = _maxPerLine - _lastLine.length;

    for (let i = 0; i < _nbRequire; i++) {
      let _newDiv = document.createElement('div');
      _newDiv.classList.add('uielement-gallery-element', 'uielement-gallery-element-fake');
      _container.appendChild(_newDiv);

    }
  }

  filter(galleryId) {
    const matches = (galleryId) ? this.itemsTargets.filter(
      (item) => {
        return (item.dataset.galleryId == galleryId);
      }
    ) : this.itemsTargets;

    gsap.to(this.itemsTargets, {opacity: 0, scale: .96, duration: .5}).then(() => {
      this.itemsTargets.forEach(item => item.style.display = matches.indexOf(item) === -1 ? "none" : "inline-flex");
      this.fillLastLine();
      gsap.fromTo(matches, {scale: 1.05}, {stagger: 0.02, scale: 1, duration: .5});
      gsap.to(matches, {stagger: 0.02, opacity: 1, duration: .5, ease: "power2.in"});


    });

  }

  @boundMethod
  onClickFilter(evt) {
    this.filter(evt.target.dataset.id);

  }

  connect() {
    this.gLightBox = GLightbox({
      loop: true,
      zoomable: false,
      closeOnOutsideClick: true,
      selector: '.glightbox',
      closeEffect: 'fade',
      lightboxHTML: this.customLightboxHTML,
      slideHTML: this.customSlideHTML,
    });
    this.gLightBox.on('open', this.onLightBoxOpened);
    this.gLightBox.on('close', this.onLightBoxClosed);
    this.gLightBox.on('slide_changed', this.onLightBoxSlideChanged);

    gsap.to(this.barTarget, {y: '0%', delay: .5});

    this.gLightBox.on('slide_before_load', (data) => {
      const { slideIndex, slideNode, slideConfig, player, trigger } = data;
      if(slideConfig.href.includes('.png')) {
        slideNode.classList.add('no-shadow');
      }
    });
    document.body.appendChild(this.unDownloadableInfo);

  }

  @boundMethod
  onWheel(evt) {
    if (this.debounce) {
      return;
    }
    this.debounce = setTimeout(() => {
      this.debounce = null;
    }, 300);

    let delta = evt.deltaY || evt.detail || evt.wheelDelta;
    if (delta < 0) {
      this.gLightBox.prevSlide();
    } else {
      this.gLightBox.nextSlide();
    }
  }


  get currentSlideElement() {
    let currentElt = this.gLightBox.elements[this.gLightBox.getActiveSlideIndex()];
    return currentElt;
  }

  @boundMethod
  onLightBoxSlideChanged(evt) {
    this.swapTitle(this.currentSlideElement.title);
    this.downloadable(this.currentSlideElement.title);

  }

  @boundMethod
  swapTitle(title) {
    const duration = this.titleTarget.innerHTML == '' ? 0 : .5;
    const opacity = 0;

    gsap.to(this.titleTarget, {duration, opacity}).then(() => {
      this.titleTarget.innerHTML = title;
      gsap.to(this.titleTarget, {duration: .5, opacity: 1});
    });
  }

  @boundMethod
  onLightBoxOpened() {
    this.element.classList.add('lightbox-opened');
    this.gLightBox.modal.addEventListener('wheel', this.onWheel, {passive: true});

  }

  @boundMethod
  onLightBoxClosed() {
    this.unDownloadableInfo.classList.remove('show');
    this.element.classList.remove('lightbox-opened');
    this.gLightBox.modal.removeEventListener('wheel', this.onWheel);
    this.swapTitle('')

  }

  downloadRequire() {
    const link = document.createElement('a');
    link.setAttribute('data-no-swup', 1);
    link.target = '_blank';
    link.href = this.currentSlideElement.node.dataset.download;
    link.download = link.href.substring(link.href.lastIndexOf('/') + 1);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  }

  closeLightBox() {
    this.onLightBoxClosed();
    this.gLightBox.close();

  }

  openLightBox() {
    this.gLightBox.open();

  }

  disconnect() {
    console.log('%c disconnect  ', 'background-color:#B36D35');

    document.body.classList.remove('galerie-open');
    try {
      this.unDownloadableInfo.remove();
      this.gLightBox.modal.removeEventListener('wheel', this.onWheel);
      this.gLightBox.destroy();
    } catch (e) {
    }
  }

  downloadable() {
    this.downloadTarget.classList.toggle('show', this.currentSlideElement.node.dataset.downloadable);
    this.unDownloadableInfo.classList.toggle('show', !this.currentSlideElement.node.dataset.downloadable);
  }
}
