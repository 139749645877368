import {Controller} from '@hotwired/stimulus';
import {boundMethod} from 'autobind-decorator';
import {gsap} from 'gsap';
import DrawSvg_controller from "../animations/draw-svg_controller";

export default class extends Controller {

  static values = {
    src: String || '',
  };

  static targets = ['video', 'bg', 'text', 'logo', 'textintro', 'logointro'];

  _introDelay = 0;

  initialize() {
    super.initialize();
    this.html = document.querySelector('html');
    this.text = (!this.hasTextTarget)? document.querySelector('[data-ui--video-target="text"]'): this.textTarget;
    this.logo = (!this.hasLogoTarget)? document.querySelector('[data-ui--video-target="logo"]'): this.logoTarget;
    this.logoPaths = this.logo.querySelectorAll('[data-animations--draw-svg-target="path"]');
    this.textintro = (!this.hasTextintroTarget)? document.querySelector('[data-ui--video-target="textintro"]'): this.textintroTarget;
    this.logointro = (!this.hasLogointroTarget)? document.querySelector('[data-ui--video-target="logointro"]'): this.logointroTarget;
    //this.logointroPaths = this.logointro.querySelectorAll('[data-animations--draw-svg-target="path"]');
    //this.logointroSVG =  this.logointro.querySelector('svg');
    //this.logointroSVG.setAttribute('data-animations--draw-svg-duration-value', '1.8');
   // this.logointroSVG.setAttribute('data-animations--draw-svg-delay-value', '0');
    //this.logointroSVG.setAttribute('data-animations--draw-svg-between-value', '1.5');
    gsap.set([this.videoTarget, this.bgTarget,  this.logo, this.logointro], {
      opacity: 0,
    })
  }

  @boundMethod
  connect() {
    this.logoPaths.forEach(el => el.closest('svg').dispatchEvent(new CustomEvent('disappear:el', {detail: this})));
   // this.logointroPaths.forEach(el => el.closest('svg').dispatchEvent(new CustomEvent('disappear:el', {detail: this})));
    //console.log('🎬 video controller connected');
    const sourceTag = this.createSourceTag(this.srcValue);
    this.videoTarget.appendChild(sourceTag);
    this.videoTarget.addEventListener('ended', this.onEnded);
    this.videoTarget.addEventListener('play', this.onStart);
    this.videoTarget.currentTime = 0;
    this.videoTarget.play().then(()=>{
      this.introDelay = this.html.classList.contains('is-intro') ? 0 : this.introDelay;
      setTimeout(() => {
        gsap.to(this.videoTarget, {duration: 0.6, opacity: 1,  ease: "sine.in"});
      }, this.introDelay*0.8);
      setTimeout(() => {
        this.videoTarget.dispatchEvent(new CustomEvent('lazy-image:ready', {detail: {}, bubbles: true}));
        document.dispatchEvent(new CustomEvent('video:ready', {detail: {}, bubbles: true}));
      }, this.introDelay);
    });
    this.videoTarget.addEventListener('timeupdate', this.timeUpdate);
  }

  @boundMethod
  createSourceTag (src) {
    const source = document.createElement('source');
    source.src = src;
    return source;
  }

  // async preloadVideo(src) {
  //   return new Promise((resolve, reject) => {
  //     if(document.querySelector('[data-ui--video-target="video"]').closest('source'))  {
  //       resolve(false);
  //     }
  //     const video = document.createElement("video");
  //     const source = document.createElement("source");
  //     source.src = src;
  //     video.append(source);
  //     video.addEventListener("loadeddata", () => {
  //       resolve(source);
  //     });
  //     video.onerror = (error) => {
  //       reject(error);
  //     };
  //   });
  // }



  @boundMethod
  timeUpdate () {
    this.showLogoIntro();

    if (this.videoTarget.currentTime > this.videoTarget.duration * 0) {
      gsap.to(this.logointro.closest('h1').querySelector('.title'),{opacity:0, duration:1.3});
    }

    if (this.videoTarget.currentTime > this.videoTarget.duration * 0.21) {
      this.showLogo();
    }
    if (this.videoTarget.currentTime > this.videoTarget.duration * 0.25) {
      this.showText();
    }
    if (this.videoTarget.currentTime > this.videoTarget.duration * 0.9) {
      this.showImage();
      this.videoTarget.removeEventListener('timeupdate', this.timeUpdate);
    }
  }

  @boundMethod
  showLogoIntro() {
    if(this.logointro.classList && this.logointro.classList.contains('appear')) return;
    this.logointro.classList.add('appear');
    gsap.set(this.logointro,{opacity:1});
   // this.logointroPaths.forEach(el => el.closest('svg').dispatchEvent(new CustomEvent('appear:el',{detail: this})));

  }

  @boundMethod
  showLogo() {
    if(this.logo.classList && this.logo.classList.contains('appear')) return;
    this.logo.classList.add('appear');
    gsap.set(this.logo,{opacity:1});
    gsap.to(this.textintro,{opacity:0, duration:0.6, onComplete:() =>{
        this.textintro.classList.add('hidden');
      } });
    this.logoPaths.forEach(el => el.closest('svg').dispatchEvent(new CustomEvent('appear:el',{detail: this})));
  }

  @boundMethod
  showText() {
    if(this.text.classList.contains('appear')) return;
    document.dispatchEvent(new CustomEvent('video:showtext', {detail: {}, bubbles: true}));
    this.text.classList.add('appear');
    gsap.set(this.text,{opacity:1});
  }

  @boundMethod
  showImage() {
    gsap.set(this.bgTarget,{opacity:1});
    gsap.to(this.videoTarget, {
      opacity: 0,
      duration: 2,
      delay:0.1,
      ease: 'power2.out',
    })
  }

  @boundMethod
  onStart() {
    //console.log('⏯ video started');
    document.dispatchEvent(new CustomEvent('video:start', {detail: {}, bubbles: true}));
  }

  @boundMethod
  onEnded() {
    //console.log('⏹ video ended');
    // this.videoTarget.currentTime = 0.35;
    // this.videoTarget.play();
  }


  disconnect() {
    this.videoTarget.removeEventListener('timeupdate', this.timeUpdate);
    this.videoTarget.removeEventListener('ended', this.onEnded);
    this.videoTarget.removeEventListener('play', this.onStart);
  }

  get introDelay() {
    return this._introDelay;
  }

  set introDelay(value) {
    if (value !== this._introDelay) this._introDelay = value;
  }

}
