import { boundMethod } from 'autobind-decorator';
import { useHover } from 'stimulus-use';
import { Controller } from '@hotwired/stimulus';
import gsap from 'gsap';

export default class extends Controller {
  initialize () {
    useHover(this);
    this.mouseEnterComplete = false;
    this.isMouseEnter       = false;
  }

  @boundMethod
  mouseEnter() {
    this.mouseEnterComplete = false;
    this.isMouseEnter       = true;
    gsap.to(this.element,  {duration:.2,opacity: 1,z:-50}).then( ()=>{
      this.mouseEnterComplete = true;
      gsap.to(this.element,  {duration:.7,z: 0});
      (this.isMouseEnter === false && this.animLeave());
    });

  }

  @boundMethod
  animLeave(){

  }

  @boundMethod
  mouseLeave() {
    this.isMouseEnter = false;
    (this.mouseEnterComplete === true && this.animLeave());
  }

}
