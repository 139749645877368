import { Controller } from '@hotwired/stimulus';
import { useIntersection } from 'stimulus-use';

// eslint-disable-next-line camelcase
class default_1 extends Controller {
  initialize() {
    super.initialize();
  }


  appear(entry) {
    this.unobserve();
    const hd = (this.element.classList.contains('lazyloaded')) ?  this.element :  new Image();
    const srcsetString = this._calculateSrcsetString();
    if (this.element.classList.contains('lazyloaded')) {
      this.element.classList.add('lazy-image-loaded');
      this._dispatchEvent('lazy-image:ready', { image: this.element });
    } else {
      this.element.src = this.srcValue;
      hd.addEventListener('load', () => {
        this.element.src = this.srcValue;
        if (srcsetString) {
          this.element.srcset = srcsetString;
        }
        this.element.classList.add('lazy-image-loaded');
        this._dispatchEvent('lazy-image:ready', { image: hd });
      });
    }
    hd.src = this.srcValue;
    if (srcsetString) {
      hd.srcset = srcsetString;
    }
    this._dispatchEvent('lazy-image:connect', { image: hd });
  }

  disconnect() {
    this.unobserve();
    super.disconnect();
  }

  connect() {
    const [observeIntersection, unobserveIntersection] = useIntersection(
      this,
      this.optionsValue || {},
    );
    this.unobserve = () => unobserveIntersection();
  }

  _calculateSrcsetString() {
    if (!this.hasSrcsetValue) {
      return '';
    }
    const sets = Object.keys(this.srcsetValue).map(size => `${this.srcsetValue[size]} ${size}`);
    return sets.join(', ').trimEnd();
  }

  _dispatchEvent(name, payload) {
    this.element.dispatchEvent(new CustomEvent(name, { detail: payload }));
  }
}

default_1.values = {
  src: String,
  options: Object,
  srcset: Object,
};

export { default_1 as default };
