import { Controller } from "@hotwired/stimulus";
import { boundMethod } from "autobind-decorator";

export default class extends Controller {

  static targets = ['form', 'link']

  @boundMethod
  onChange(e) {
    this.linkTarget.href =  e.currentTarget.selectedOptions[0].dataset.url;
  }

  @boundMethod
  onChangeSelect(e) {
    window.appSwup.navigate(e.currentTarget.selectedOptions[0].dataset.url);
  }

}
