import {boundMethod} from 'autobind-decorator';
import {Controller} from '@hotwired/stimulus';
import gsap from 'gsap';
import GoogleMapsApi from '../../js/libs/GoogleMapsApi';
import {MarkerClusterer, SuperClusterAlgorithm} from "@googlemaps/markerclusterer";
import Marker from './marker';

export default class extends Controller {
  static targets = ['mapContainer', 'searchInput', 'importerPopup', 'resultsContainer'];
  static values = {
    stores: Array,
    defaultLng: Number,
    defaultLat: Number,
  };


  connect() {
    document.dispatchEvent(new CustomEvent('video:showtext', {detail: {}, bubbles: true}));
    this.selectedIndex = 0;
    const gmapApi = new GoogleMapsApi();
    gmapApi.load().then(() => {
      this.createMap();
      this.createMarkers();


      this.addCluster();

      this.map.addListener('idle', this.showVisibleStores);

    });
  }

  addCluster() {

    this.markerCluster = new MarkerClusterer(
      {
        algorithm: new SuperClusterAlgorithm({
          radius: 125,
          extent: 200,
          maxZoom: 12,
          nodeSize: 340

        }),
        markers: this.stores.map((store) => {
          return store.marker;
        }),
        map: this.map,
        renderer: {
          render: ({count, position}) =>

            new google.maps.Marker({
              icon: {
                path: google.maps.SymbolPath.CIRCLE,
                // scale progessive form 0 to 100
                scale: this.power4EaseOut(Math.min((count + 25) / 600, 1)) * 100,
                /*scale:       Math.min(Math.max(count * 0.5, 20), 85),*/

                fillColor: '#B25267',
                fillOpacity: 0.8,
                strokeColor: '#fff',
                strokeWeight: 1,
              },
              label: {text: String(count), color: "white", fontSize: "16px"},
              position,
              visible: count > 0,
              // adjust zIndex to be above other markers
              zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
            }),
        },
        onClusterClick: ((event,cluster,map) => {

                  map.setCenter(cluster.position); // zoom to the cluster center
           map.setZoom(map.getZoom()+3);

        }),
      });
   /* google.maps.event.addListener(this.map, "tilesloaded", () => {
      //     this.markerCluster.clearMarkers();
      if (this.markerCluster.map) {
        this.markerCluster.addMarkers(this.currentMarkers = this.stores.map(marker => {
            //   marker.marker.getVisible() && this.map.getBounds().contains(marker.marker.getPosition())
            try {
              if (this.map.getBounds().contains(marker.marker.getPosition()))
              {
                marker.marker.getVisible() && marker.marker.setVisible(true);
              }
            else
              {
                !marker.marker.getVisible() && marker.marker.setVisible(false);
              }
            }
          }
        ));
      }
    });*/


  }

  cubicEaseOut(x) {
    return 1 - (1 - x) * (1 - x) * (1 - x);
  }

  power4EaseOut(x) {
    return 1 - (1 - x) * (1 - x) * (1 - x) * (1 - x);
  }

  easeOutQuad(x) {
    return 1 - (1 - x) * (1 - x);
  }

  disconnect() {
    this.stores.forEach(store => {
      store.disconnect();
    });
    this.stores = null;
    google.maps.event.clearInstanceListeners(this.map);
  }

  @boundMethod
  onSearchInput(evt) {
    // console.log('onSearchInput',evt.currentTarget.value);
  }

  @boundMethod
  onSubmitSearch(evt) {
    evt.preventDefault();
    const {value} = this.searchInputTargets.filter((input) => evt.currentTarget.querySelector('input') === input)[0];
    const search = encodeURI(value);
    fetch(`https://nominatim.openstreetmap.org/search.php?q=${search}&polygon_geojson=1&format=jsonv2`)
      .then(response => response.json())
      .then((json) => {
        if (json[0]) {
          this.map.panTo(new google.maps.LatLng(json[0].lat, json[0].lon));
          // Calculate the difference in latitude and longitude

          const bounds = new google.maps.LatLngBounds();
          bounds.extend(new google.maps.LatLng(json[0].boundingbox[0], json[0].boundingbox[2]));
          bounds.extend(new google.maps.LatLng(json[0].boundingbox[1], json[0].boundingbox[3]));

          // Récupérer les coordonnées centrales des limites de la boîte englobante
          const center = bounds.getCenter();

          // Centrer la carte sur les coordonnées centrales
          this.map.setCenter(center);


          // Adjust the map zoom and position automatically
          this.map.fitBounds(bounds);

          if (this.map.getZoom() > 16) {
            this.map.setZoom(16);
          }

        }
      });

  }

  showMarker(evt) {
    const storeId = parseInt(evt.currentTarget.dataset.storeId);
    this.showListMarker(storeId);
  }

  getVisibleMarkers() {
    const bounds = this.map.getBounds();
    return this.stores.filter(store => {
      return bounds.contains(store.marker.getPosition());
    });

  }

  showListMarker(storeId) {
    let lat = 0;
    let lng = 0;

    let currentMarker = null;
    this.stores.map((store) => {

      if (parseInt(store.marker.index) === storeId) {
        /*store.marker.setVisible(true);*/
        this.selectedIndex = storeId;
        // change icon to show marker is selected
        store.marker.setIcon(
          store.store.svghover,
        );
        currentMarker = store.marker;
        // get lat and lng of marker
        lat = store.marker.getPosition().lat();
        lng = store.marker.getPosition().lng();
        try {
          document.querySelector(`[data-store-id="${storeId}"]`).classList.add('active');

        } catch (e) {

        }

        //    store.element.classList.add('active');
      } else {
        //store.marker.setVisible(false);
        store.marker.setIcon(
          store.store.svg,
        );
        // store.element.classList.remove('active');
        //    this.resultsContainerTarget.querySelector(`[data-store-id="${storeId}"]`).classList.remove('active');
      }
      // return store.marker;
    })

    // if lat is defined
    if (lat != 0 && lng != 0) {
      this.map.panTo(new google.maps.LatLng(lat, lng));
      this.map.setZoom(16);
    }

    if (this.is_screensmartphone()) {
      this.setInfosMarkerContent(storeId);

      this.infoWindow.open(this.map, currentMarker);
    }

  }

  @boundMethod
  setInfosMarkerContent(storeId) {

    const langue = window.location.pathname.split('/')[1] || 'fr_FR';
    let data_result = "";
    const url = '/' + langue + '/api/storelocator';

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        storeIds: [storeId],
        onlyOne: true,
        selectedIndex: storeId
      }),

    }


    fetch(url, options)
      .then(response => response.json())
      .then((json) => {
          this.infoWindow.setContent(json.html.content);
        }
      );
  }

  onClickLocateMe() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(((position) => {
          this.map.panTo(new google.maps.LatLng(position.coords.latitude, position.coords.longitude));
          this.map.setZoom(13);
        }),
        null,
        {enableHighAccuracy: true});
    }
  }


  toggleImporterTarget() {
    if(this.importerPopupTarget) {
      // Toggle the display of the importerTarget element
      if(this.importerPopupTarget.style.display === 'none' || this.importerPopupTarget.style.display === '') {
        this.importerPopupTarget.style.display = 'block';
      } else {
        this.importerPopupTarget.style.display = 'none';
      }
    }
  }


  createMarkers() {
    this.stores = this.storesValue.map((store) => {
      const m = new Marker(this.map, this.element, store);
      // add click on marker event

      m.marker.addListener("click", (e) => {
        this.showListMarker(store.id);
      });
      return m;
    });

  }

  @boundMethod
  clickMark(e, i) {

    this.showListMarker(this.index);
  }

  is_screensmartphone() {
    return window.innerWidth < 868;
  }

  @boundMethod
  showVisibleStores() {

    const visibleStoreIds = this.getVisibleStoreIds()
    if (this.is_screensmartphone()) return;


    const langue = window.location.pathname.split('/')[1] || 'fr_FR';

    const url = '/' + langue + '/api/storelocator';
    // fetch url and post visibleStoreIds

    fetch(url,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          storeIds: visibleStoreIds,
          onlyOne: false,
          selectedIndex: this.selectedIndex
        }),

      })
      .then(response => response.json())
      .then((json) => {
        this.resultsContainerTarget.innerHTML = json.html.content;

      });


  }

  @boundMethod
  updateCategories() {
    this.showVisibleStores();
    this.markerCluster.clearMarkers();
    this.addCluster();
  }

  @boundMethod
  getVisibleStoreIds() {


    //get checkbox categorie values
    let categories = [];
    if (this.is_screensmartphone()) {
      categories = this.element.querySelectorAll('input.smart_input-categorie[type="checkbox"]:checked');
    } else {
      categories = this.element.querySelectorAll('input.input-categorie[type="checkbox"]:checked');
    }
    const categoriesArray = Array.from(categories);
    const categoriesIds = categoriesArray.map((category) => {
      // return int value of category id
      return parseInt(category.value);
    });
    const bounds = this.map.getBounds();
    const visibleMarkers = this.stores.filter(store => {
      if (categoriesIds.includes(store.store.cat)) {
        store.marker.setVisible(bounds.contains(store.marker.getPosition()));
        return bounds.contains(store.marker.getPosition());
      } else {
        store.marker.setVisible(false);
      }
    });


    /*  this.stores.forEach((store) => {

        let show = true;

        //if store categories is not in categoriesIds
        if (!categoriesIds.includes(store.store.cat)) {
          show = false;
        }
        if (!bounds.contains(store.marker.getPosition())) {
          show = false;
        }
        if (show) {
      //    store.element.classList.remove('hidden');
          // store.marker set visible false
         // store.marker.setVisible(true);



        } else {
    //      store.element.classList.add('hidden');
    //      store.marker.setVisible(false);
        }
      });
  */
    // count visible markers
    const visibleMarkersCount = visibleMarkers.length;

    // return visibleMarkers ids
    const visibleMarkersIds = visibleMarkers.map((store) => {
      return store.store.id;
    });
    return visibleMarkersIds;

  }


  createMap() {
    this.map = new google.maps.Map(
      this.mapContainerTarget,
      {
        center: {lat: this.defaultLatValue, lng: this.defaultLngValue},
        zoom: this.is_screensmartphone() ? 5.5 : 6,
        mapTypeControl: false,
        scrollwheel: true,
        fullscreenControl: this.is_screensmartphone() ? true : false,
        gestureHandling: "greedy",
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        },
        navigationControl: true,
        navigationControlOptions: {
          style: google.maps.NavigationControlStyle.ZOOM_PAN,
        },
        streetViewControl: false,
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_TOP,
        },
      },
    );

    this.map.setOptions({styles: this.mapStyle});


    this.infoWindow = new google.maps.InfoWindow({
      content: '',
    });

// use default algorithm and renderer
  }


  get mapStyle(){
    return [
      {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#444444"
          }
        ]
      },
      {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
          {
            "color": "#f2f2f2"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
          {
            "saturation": -100
          },
          {
            "lightness": 45
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
          {
            "visibility": "simplified"
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
          {
            "color": "#ddd5cb"
          },
          {
            "visibility": "on"
          }
        ]
      }
    ];
  }

}
