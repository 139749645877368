import {IntersectionController} from "stimulus-use";
import {boundMethod} from "autobind-decorator";
import gsap from 'gsap';
import DrawSVGPlugin from "../../libs/gsap/DrawSVGPlugin";


export default class extends IntersectionController {
  static values = {
    duration: Number,
    delay: Number,
    between: Number || 5,
  };

  static options = {
    threshold: 0, // default
  };

  static targets = ['path']

  initialize() {
    super.initialize();
  }

  connect() {
    gsap.registerPlugin(DrawSVGPlugin);
    this.element.addEventListener('appear:el', this.drawSvg)
    this.element.addEventListener('disappear:el', this.drawSvgDown)
  }

  @boundMethod
  drawSvg(entry) {
    if( entry.target.id === "logo-fill"){
      gsap.set([this.pathTarget], {drawSVG: "0% 0%"});
      this.pathTarget.classList.add('no-transition');
      gsap.to(
        this.pathTarget,
        {
          ease: 'sine.inOut',
          drawSVG: true,
          duration: this.durationValue || 0.4,
          delay: this.delayValue || 0,
          stagger: 0.1,
          onComplete: () => {
            this.pathTarget.classList.remove('no-transition');
            gsap.set(this.pathTarget, {clearProps: 'all'});
          }
        },
      );
    }else{
      const target = (entry.detail) ?? this.pathTargets;
      gsap.set([target], {drawSVG: "0% 0%"});
      target.classList && target.classList.add('no-transition');
      gsap.set(this.pathTargets, {drawSVG: "0% 0%"});
      this.pathTargets.forEach(el => el.classList && el.classList.add('no-transition'));
      const logoG = this.pathTargets.filter(el => el.id === 'logoG')[0];
      const logoP = this.pathTargets.filter(el => el.id === 'logoP')[0];
      const logoL = this.pathTargets.filter(el => el.id === 'logoL')[0];
      const logoFillPath = this.pathTargets.filter(el => el.id === 'logoFillPath')[0];
      const logoCircle =  this.pathTargets.filter(el => el.id === 'logoCircle')[0];
      gsap.set(logoFillPath, {opacity:0, drawSVG: "100%"});
      gsap
        .to(
          logoG,
          {
            ease: 'sine.inOut',
            drawSVG: "100%",
            duration: this.durationValue || 0.4,
            delay: this.delayValue+(this.betweenValue*0) || 0,
            onStart:() =>{
              gsap.set(logoG, {fill: 'transparent'});
            },
            onComplete: () => {
              logoG.classList && logoG.classList.remove('no-transition');
              gsap.set(logoG, {clearProps: 'all'});
            }
          },
        )
        .then(gsap.to(
          logoP,
          {
            ease: 'sine.inOut',
            drawSVG: "100%",
            duration: this.durationValue || 0.4,
            delay: this.delayValue+(this.betweenValue*1) || 0.5,
            onStart:() =>{
              gsap.set(logoG, {fill: 'transparent'});
            },
            onComplete: () => {
              logoP.classList && logoP.classList.remove('no-transition');
              gsap.set(logoP, {clearProps: 'all'});
            }
          },
        ))
        .then(
          gsap.to(
          logoL,
        {
          ease: 'sine.inOut',
          drawSVG: "100%",
          duration: this.durationValue || 0.4,
          delay: this.delayValue+(this.betweenValue*2) || 1,
          onStart:() =>{
            gsap.set(logoG, {fill: 'transparent'});
          },
          onComplete: () => {
            logoL.classList && logoL.classList.remove('no-transition');
          //  gsap.set(logoL, {clearProps: 'all'});
          }
        },
      ))
        .then(
          gsap.to(
            logoFillPath,
            {
              ease: 'sine.inOut',
              opacity: 1,
              duration: 1,
              delay: this.delayValue+(this.betweenValue*3) || 1,
              onStart:() =>{
                gsap.to([logoG,logoP,logoL],
                  {
                    duration:1,
                    opacity:0,
                    delay:0,
                    ease: 'sine.inOut',
                  });
              },
              onComplete: () => {
                logoFillPath.classList && logoFillPath.classList.remove('no-transition');
              }
            },
          ))
        .then(
          gsap.to(
            logoCircle,
            {
              ease: 'sine.inOut',
              drawSVG: "100%",
              duration: this.durationValue || 0.4,
              delay: this.delayValue+15 || 1,
              onComplete: () => {
                logoCircle.classList && logoCircle.classList.remove('no-transition');
                gsap.set(logoCircle, {clearProps: 'all'});
              }
            },
          ))
      ;
    }
  }

  @boundMethod
  drawSvgDown(entry) {
    const target = (entry.detail) ?? this.pathTargets;
    target.classList && target.classList.add('no-transition');
    gsap.to(
      target,
      {
        ease: 'sine.inOut',
        drawSVG: "0%",
        duration: this.durationValue || 0.4,
        delay: this.delayValue || 0,
        stagger: 0.1,
        onComplete: () => {
          target.classList && target.classList.remove('no-transition');
        }
      },
    );
  }

  disconnect() {
    this.pathTargets && gsap.killTweensOf(this.pathTargets);
  }


}
