import {IntersectionController} from "stimulus-use";
import {boundMethod} from "autobind-decorator";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {ScrollToPlugin} from "gsap/ScrollToPlugin";
import {Observer} from "gsap/Observer";
import Scrollbar from 'smooth-scrollbar';
import HorizontalScrollPlugin from "../../libs/HorizontalScrollPlugin";
import { getCurrentUrl, updateHistoryRecord,createHistoryRecord } from 'swup';


export default class extends IntersectionController {

  static targets = ["ancre"];

  scrollX = 0;

  initialize() {
    super.initialize();
    this._horizontal = undefined;
  }

  connect() {
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, Observer);
    super.connect();
    Scrollbar.use(HorizontalScrollPlugin);
    this.body = document.querySelector("body");
    this.page = document.querySelector(".page");
    this.sections = gsap.utils.toArray(".page .bloc");
    this.horizontalScrollTrigger = (window.innerWidth >= 868);
    window.addEventListener('resize', this.onResize);
    window.addEventListener('hashchange', this.onHashChange);
  }

  set horizontalScrollTrigger(value) {
    if (value !== this._horizontal) {
      if (!value && ScrollTrigger.getAll() > 0) {
        this.bodyScrollBar && this.bodyScrollBar.destroy();
        Observer.getAll(o => o.kill());
      }
      if (value !== this._horizontal && value || value !== this._horizontal && !value) {
        //  ScrollTrigger.getAll().forEach(st => (st.vars.id !== 'parallax')? st.kill(): '');
        ScrollTrigger.killAll();
      }
      this._horizontal = value;
      this.initScroll();
    }
  }

  get horizontalScrollTrigger() {
    return this._horizontal;
  }

  @boundMethod
  onClickAncre(e) {
    e.preventDefault();
    e.stopImmediatePropagation();
    createHistoryRecord(getCurrentUrl(window.location.hash)+e.params.hash);
    this.onScrollToHash(e);
  }

  recoverScrollHash(hash) {
    if (!hash) return false;
    const hashEl = document.querySelector(hash);
    if (!hashEl) return false;
    if (document.querySelector(`[data-controller="ui--horizontal-scrolltrigger"]`) && (window.innerWidth >= 1024)) {
      return hashEl.offsetLeft;
    }
    return hashEl.offsetTop;
  }

  @boundMethod
  onResize() {
    this.horizontalScrollTrigger = (window.innerWidth >= 868);
  }

  @boundMethod
  onHashChange(e) {
    e.preventDefault();
    e.stopPropagation();
    this.scrollX = this.recoverScrollHash(window.location.hash);
    if (window.innerWidth >= 1024) {
      window.bodyScrollBar.scrollTo(this.scrollX, 0, 1000);
    } else {
      gsap.to(document.body, {duration: 2, scrollTo: this.scrollX}); // For Safari
      gsap.to(document.documentElement, {
        duration: 0.6, scrollTo: this.scrollX
      }); // For Chrome, Firefox, IE and Opera
    }
  }

  @boundMethod
  onScrollToHash(e) {
    e.preventDefault();
    e.stopPropagation();
    this.scrollX = this.recoverScrollHash(window.location.hash);
    if (window.innerWidth >= 1024) {
      window.bodyScrollBar.scrollTo(this.scrollX, 0, 1000);
    } else {
      gsap.to(document.body, {duration: 2, scrollTo: this.scrollX}); // For Safari
      gsap.to(document.documentElement, {
        duration: 0.6, scrollTo: this.scrollX
      }); // For Chrome, Firefox, IE and Opera
    }
  }

  @boundMethod
  initScroll() {
    this.scroller = window;

    if (this.horizontalScrollTrigger) {
      this.scroller = this.page;
      this.dragRatio = 1;
      this.bodyScrollBar = Scrollbar.init(this.scroller, {damping: 0.1, delegateTo: window, alwaysShowTracks: true});
      window.bodyScrollBar = this.bodyScrollBar;

      ScrollTrigger.scrollerProxy(this.page, {
        scrollLeft: (value) => {
          if (arguments.length) {
            this.bodyScrollBar.scrollLeft = value;
          }
          return this.bodyScrollBar.scrollLeft;
        }
      });
    }

    ScrollTrigger.defaults({
      scroller: this.scroller,
      horizontal: this.horizontalScrollTrigger,
    });

    ScrollTrigger.config({autoRefreshEvents: "DOMContentLoaded,load,resize"});


    this.sections.forEach((section, index) => {
      ScrollTrigger.create({
        trigger: section,
        start: "top bottom",
        end: "bottom top",
        onRefresh: (self) => {
          //console.log('on refresh');
          if (this.horizontalScrollTrigger) {
            this.observerScroll(self);
            this.dragRatio =
              (self.end - self.start) /
              (this.page.offsetWidth);
          }
        },
        // markers:true,
      });
    });


    if (this.horizontalScrollTrigger) {
      this.bodyScrollBar.addListener(ScrollTrigger.update);
    }
  }

  @boundMethod
  observerScroll(scrollTween) {
    const clamp = gsap.utils.clamp(-60, 60);
    Observer.create({
      target: this.scroller,
      type: "touch,pointer,wheel",
      onWheel: (self) => {
      },
      onPress: (self) => {
        const target = self.event.target;
        self.onPress = !target.classList.contains('scrollbar-thumb');
        if (self.onPress && this.bodyScrollBar.options.plugins.horizontalScroll.enable) {
          self.startScroll = scrollTween.scroll();
        }
      },
      onDrag: (self) => {
        if (self.onPress && this.bodyScrollBar.options.plugins.horizontalScroll.enable) {
          this.bodyScrollBar.addMomentum(clamp(self.velocityX * -0.003) * this.dragRatio, 0);
        }
      },
    });

    this.easeOutSine = (x) => {
      return Math.sin((x * Math.PI) / 2);
    }
    this.defaultEasing = (t) => {
      return (t - 1) ** 3 + 1;
    }

    window.addEventListener('keydown', this.onKeyDown);
  }

  @boundMethod
  onKeyDown(event) {
    if (event.key === 'ArrowDown') {
      this.bodyScrollBar.addMomentum(300, 0);
    }
    if (event.key === 'ArrowUp') {
      this.bodyScrollBar.addMomentum(-300, 0);
    }
    if (event.key === 'ArrowRight') {
      this.bodyScrollBar.addMomentum(300, 0);
    }
    if (event.key === 'ArrowLeft') {
      this.bodyScrollBar.addMomentum(-300, 0);
    }
  }

  @boundMethod
  appear() {
    this.unobserve();
  }

  disconnect() {
    window.removeEventListener('resize', this.onResize);
    this.bodyScrollBar && this.bodyScrollBar.destroy();
    Observer.getAll(o => o.kill());
    ScrollTrigger.killAll()
    super.disconnect();
  }
}
