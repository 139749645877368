import {Controller} from '@hotwired/stimulus';
import {boundMethod} from 'autobind-decorator';
import {gsap} from 'gsap';

export default class extends Controller {
  static targets = ['video', 'image'];

  _introDelay = 1000;

  initialize() {
    super.initialize();
    this.html = document.querySelector('html');
    gsap.set([this.videoTarget], {
      opacity: 0,
    })
  }

  @boundMethod
  connect() {
    console.log('🎬 video controller connected');
    this.videoTarget.addEventListener('ended', this.onEnded);
    this.videoTarget.currentTime = 0;
   // this.videoTarget.addEventListener('timeupdate', this.timeUpdate);
  }

  @boundMethod
  onStartPlay(e){
    e.preventDefault();
    e.stopImmediatePropagation();
    gsap.to(this.imageTarget, { duration: 0.4, opacity:0});
    // this.videoTarget.addEventListener('play', this.onStart);
    this.videoTarget.play().then(() => {
      this.introDelay = this.html.classList.contains('is-intro') ? 0 : this.introDelay;
      setTimeout(() => {
        gsap.to(this.videoTarget, {duration: 0.6, opacity: 1,  ease: "sine.out"});
      }, this.introDelay*0.8);
      setTimeout(() => {
        this.videoTarget.dispatchEvent(new CustomEvent('lazy-image:ready', {detail: {}, bubbles: true}));
        document.dispatchEvent(new CustomEvent('video:ready', {detail: {}, bubbles: true}));
      }, this.introDelay);

    });
  }

  @boundMethod
  timeUpdate () {
    if (this.videoTarget.currentTime > this.videoTarget.duration * 0.8) {
      this.videoTarget.removeEventListener('timeupdate', this.timeUpdate);
    }
  }

  @boundMethod
  onStart() {
    //console.log('⏯ video started');
  }

  @boundMethod
  onEnded() {
    //console.log('⏹ video ended');
    this.videoTarget.currentTime = 0.35;
    this.videoTarget.play();
  }


  disconnect() {
    this.videoTarget.removeEventListener('ended', this.onEnded);
    this.videoTarget.removeEventListener('play', this.onEnded);
    document.removeEventListener('swup:enabled', this.afterIntro);
  }

  get introDelay() {
    return this._introDelay;
  }

  set introDelay(value) {
    if (value !== this._introDelay) this._introDelay = value;
  }

}
