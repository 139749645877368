import { app } from '../js/bootstrap';
import AppController from './controllers/app_controller';
import ElementController from './controllers/element_controller';

import './styles/index.scss';

import './img/arrow.svg';
import './img/download.svg';
import './img/cross.svg';
import './img/slideshow.svg';
import './img/grid.svg';
import './img/close-gallery.svg';


app.register('vinium-sylius-image-gallery-plugin--app', AppController);
app.register('vinium-sylius-image-gallery-plugin--element', ElementController);
