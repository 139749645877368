import {IntersectionController} from "stimulus-use";
import {boundMethod} from "autobind-decorator";
import gsap from 'gsap';


export default class extends IntersectionController {
  static values = {
    duration: Number,
    delay: Number,
  };

  static options = {
    threshold: 0, // default
  };

  static targets = ['chevron']

  initialize() {
    super.initialize();
    this.timelineBar1 = gsap.timeline({
      repeat: -1,
      repeatDelay: 0.3,
      yoyo: true,
      delay:6,
    });
    this.timelineBar2 = gsap.timeline({
      repeat: -1,
      repeatDelay: 0.3,
      yoyo: true,
      delay:6,
    });
  }

  connect() {
    this.element.querySelectorAll('.anim_bar').forEach(el => el.addEventListener('appear:el', this.animArrow));
    gsap.set(this.chevronTarget, {opacity:0, y:-20});
    gsap.set(this.element, {
      pointerEvents:'none',
    });

  }

  @boundMethod
  animArrow(entry) {
    if(!entry.detail) return;
    let bar = entry.detail;
    gsap.set(this.element, {
      pointerEvents:'all',
    });
    if(bar.closest('.anim_bar_long')){
      this.timelineBar1
        .to(bar, { duration:3.4, delay:6, y:"-15vh", scaleY:0.28, ease: "elastic.out(1,0.6)"});
    }else{
      this.timelineBar2
        .to(bar, { duration:3.4, delay:6, y:"-10vh", scaleY:0.25, ease: "elastic.out(1,0.8)"});
    }
  }

  @boundMethod
  onClick(entry) {
  }

  @boundMethod
  onMouseOver(entry) {
    this.timelineBar1.seek(10, false).pause();
    this.timelineBar2.seek(10, false).pause();
    gsap.fromTo(this.chevronTarget, {opacity:0,  y:-20, duration:1.2, ease:"power2.out"}, {opacity:1,  y:-1, duration:1.2, ease:"power2.out"});
  }

  @boundMethod
  onMouseOut(entry) {
    this.timelineBar1.play(11, false);
    this.timelineBar2.play(11, false);
    gsap.to(this.chevronTarget, {opacity:0,  y:20, duration:0.5, ease:"power2.in"});
    setTimeout(() =>{
      gsap.to(this.chevronTarget, {opacity:0,  y:20, duration:0.5, ease:"power2.in"});
    }, 2000);
  }


  disconnect() {
    this.element.querySelectorAll('.anim_bar').forEach(el => el.removeEventListener('appear:el', this.animArrow));
    this.timelineBar1 && this.timelineBar1.kill();
    this.timelineBar2 && this.timelineBar2.kill();
  }



}
