import {Controller} from '@hotwired/stimulus';
import {useIntersection} from 'stimulus-use';
import gsap from "gsap";
import { boundMethod } from "autobind-decorator";

export default class extends Controller {
  static values = {
    direction: String,
    duration: Number,
    delay: Number,
    ease: String,
    options: Object
  };
  options = {
    threshold: 0.2, // 0 by default
  };

  initialize() {
    super.initialize();
    this.options = this.optionsValue || { threshold: 0.2}
  }

  connect() {
    const [observeIntersection, unobserveIntersection] = useIntersection(
        this,
        this.optionsValue || {},
    );
    this.unobserveIntersection = () => unobserveIntersection();
    gsap.set(this.element, {
      opacity: 0,
    });
  }

  animateClipPolygon() {
    gsap.to(this.element, {
      opacity: 1,
      duration: 0.5,
    });
    gsap.to(this.element, {
      clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
      duration: this.durationValue || 2,
      delay: this.delayValue || 0,
      ease: this.easeValue || "power3.out",
    });
  }
  animateClipCircle() {
    gsap.to(this.element, {
      opacity: 1,
      duration: 0.5,
    });
    gsap.to(this.element, {
      clipPath: "circle(100%)",
      duration: this.durationValue || 3,
      delay: this.delayValue || 0,
      ease: this.easeValue || "power3.out",
    });
  }

  setClipPath() {
    switch (this.directionValue) {
      case "fromLeft":
        this.element.style.clipPath = "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)";
        this.animateClipPolygon();
        break;
      case "fromRight":
        this.element.style.clipPath =
          "polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)";
        this.animateClipPolygon();
        break;
      case "fromTop":
        this.element.style.clipPath = "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)";
        this.animateClipPolygon();
        break;
      case "fromBottom":
        this.element.style.clipPath =
          "polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)";
        this.animateClipPolygon();
        break;
      case "fromCenter":
        this.element.style.clipPath =
          "polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%)";
        this.animateClipPolygon();
        break;
      case "fromCenterX":
        this.element.style.clipPath =
          "polygon(50% 0%, 50% 0%, 50% 100%, 50% 100%)";
        this.animateClipPolygon();
        break;
      case "fromCircle":
        this.element.style.clipPath = "circle(0%)";
        this.animateClipCircle();
        break;
      default:
        this.element.style.clipPath = "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)";
        this.animateClipPolygon();
    }
  }

  @boundMethod
  appear() {
    if (!this.element.closest('.popin') || this.element.closest('.popin.popin-moved') ) {
      this.unobserveIntersection();
    }
    this.setClipPath();
  }

}
