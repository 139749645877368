import './js/libs';
// start the Stimulus application
import './js/bootstrap';

import 'tailwindcss/base.css';
import 'tailwindcss/components.css';
import 'tailwindcss/utilities.css';

// any CSS you import will output into a single css file (app.scss in this case)
import './scss/main.scss';

// IMG
import './img/fd_menu.webp';
import './img/bg.webp';
import './img/fond_ecusson.webp';
import './img/fond_grappe.webp';
import './img/fond_capsule.webp';
import './img/fond-heritage.webp';
import './img/fond_fichewine.webp';
import './img/BG-caisses-vins.jpg';
import './img/distributeur_next.webp';
import './img/chateau-next.webp';
import './img/statue.png';
import './img/sculpture.png';
import './img/gargouille.png';
import './img/vaches.png';
import './img/fond-popup-origine.png';
import './img/fond-generation.webp';
import './img/fond-popup-heritage.webp';

import lazySizes from 'lazysizes';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/custommedia/ls.custommedia';
window.lazySizesConfig = window.lazySizesConfig || {};

// STORELOCATOR
import './vinium-sylius-store-locator-plugin/app.js';


// GALLERY
import './vinium-sylius-image-gallery-plugin/app.js';
