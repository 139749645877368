import { boundMethod } from "autobind-decorator";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import {IntersectionController} from "stimulus-use";

export default class extends IntersectionController {
  static values = {
    x: Number,
    y: Number,
    rotate: Number,
    scale: Number,
    duration: Number,
    threshold: Number,
    stagger: Number,
    delay: Number,
    ease: String
  };

  static targets = ["item", "textintro"]

  connect() {
    this.initAnimation();
    gsap.registerPlugin(ScrollTrigger);
  }

  @boundMethod
  appear(event) {
    if(
      this.element.dataset.action
      && this.element.dataset.action === 'video:showtext@document->animations--group-transform-home#appear'
      && event.type !== 'video:showtext'
    ){
      return;
    }
    if (!this.element.closest('.popin') || this.element.closest('.popin.popin-moved') ) {
      this.unobserve();
    }
    const _this = this;
    this.triggers = gsap.to(this.items, {
        opacity: 1,
        pointerEvents: "auto",
        duration: 1.5,
        stagger: function(index, target, list) {
          target.classList.add('appear');
          if(target.closest('.anim_bar')) {
            gsap.fromTo(target, {
              opacity: 0,
              scale: 0,
              delay: (target.closest('.anim_bar_long')) ? 6.2 : 6.5,
              ease: _this.easeValue || "power2.out",
            }, {
              opacity: 1,
              scale: 1,
              duration: 1,
              onComplete:function(){
                const bar = this._targets[0];
                bar.dispatchEvent(new CustomEvent('appear:el',{detail: bar}));
              },
            });

          }else{
            gsap.to(target, {
              x: 0,
              y: 0,
              rotate: 0,
              scale: 1,
              duration: _this.durationValue || 0.5,
              stagger: _this.staggerValue || 0.1,
              delay: _this.delayValue || 0,
              ease: _this.easeValue || "power2.out",
              // Firefox fix
              rotation: 0.1,
            });
          }
          target.dispatchEvent(new CustomEvent("appear:el"));
          return index * (_this.staggerValue || 0.1);
        },
        delay: this.delayValue || 0,
    });
  }

  @boundMethod
  initAnimation() {
    this.items = this.hasItemTarget ? this.itemTargets : this.element.querySelectorAll(":scope > *");
    gsap.set(this.items, {
      opacity: 0,
      x: this.xValue || 0,
      y: this.yValue || 0,
      rotate: this.rotateValue || 0,
      scale: this.scaleValue || 1,
    });
  }

  disconnect() {
    this.triggers && this.triggers.kill();
  }
}
