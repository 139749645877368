import { IntersectionController } from "stimulus-use";
import gsap from 'gsap';
import {boundMethod} from "autobind-decorator";


export default class extends IntersectionController {
  static values = {
    fromY: Number,
    delay: Number,
  };

  static options = {
    threshold: 0, // default
  };


  @boundMethod
  appear (entry) {
    if (!this.element.closest('.popin') || this.element.closest('.popin.popin-moved') ) {
      this.unobserve();
    }

    gsap.fromTo(
      this.element,
      {
        y: this.fromYValue || -20,
      },
      {
        duration: 1,
        ease: 'power2.out',
        y: 0,
        delay: this.delayValue || 0,
      },
    );
    gsap.to(
      this.element,
      {
        ease: 'power2.in',
        opacity: 1,
        delay: .2 + (this.delayValue || 0),
      },
    );
  }

}


