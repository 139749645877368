import { Controller } from "@hotwired/stimulus";
import gsap from "gsap";
import { boundMethod } from "autobind-decorator";
import SwupScriptsPlugin from "@swup/scripts-plugin";
import SwupBodyClassPlugin from '@swup/body-class-plugin';
import {ScrollToPlugin} from "gsap/ScrollToPlugin";

export default class AppSwup extends Controller {
  initialize() {
    super.initialize();
    this.element.addEventListener("swup:connect", this._onConnect);
  }

  @boundMethod
  _onConnect(event) {
    // console.log("SWUP controller");
    this.countContentReplaced = 0;

    const swup = event.detail.swup;
    swup.use(new SwupScriptsPlugin({ head: false, body: true, optin: true }));
    swup.use(new SwupBodyClassPlugin());
    swup.hooks.on("content:replace", (e) => {
      this.countContentReplaced++;
    });
    swup.hooks.before("content:replace", this.onWillReplaceContent);
    window.appSwup = swup;
    this.onEnabled();
  }

  connect() {

    gsap.registerPlugin(ScrollToPlugin);
    history.pushState = ((f) =>
      function pushState() {
        let ret = f.apply(this, arguments);
        window.dispatchEvent(new Event("pushstate"));
        window.dispatchEvent(new Event("locationchange"));
        return ret;
      })(history.pushState);

    history.replaceState = ((f) =>
      function replaceState() {
        let ret = f.apply(this, arguments);
        window.dispatchEvent(new Event("replacestate"));
        window.dispatchEvent(new Event("locationchange"));
        return ret;
      })(history.replaceState);

    window.addEventListener("locationchange", () => {;
      this.onLocationChange();
    });

    document.addEventListener('swup:content:replace', this.onLocationChange);
    document.addEventListener('swup:history:popstate', this.onHistoryPopstate);
    window.appSwup = this;
  }


  @boundMethod
  onHistoryPopstate(event) {
     this.onEnabled();
  }

  @boundMethod
  onLocationChange() {
    if (this.historyBackBtns && this.historyBackBtns.length > 0) this.historyBackBtns.forEach(btn => btn.removeEventListener('click', this.onHistoryBack));
    this.historyBackBtns = document.querySelectorAll('.historyback');
    if (this.historyBackBtns.length > 0) {
      this.historyBackBtns.forEach(btn => btn.addEventListener('click', this.onHistoryBack));
    }

    document
      .querySelectorAll(
        `[href],[selected-regex-href],[data-selected-regex-href]`
      )
      .forEach((elt) => {
        elt.classList.remove("selected");
      });

    document
      .querySelectorAll(
        `[href='${window.location.pathname}'],[selected-href='${window.location.pathname}']`
      )
      .forEach((elt) => {
        elt.classList.add("selected");
      });

    document.querySelectorAll(`[data-selected-regex-href]`).forEach((elt) => {
      if (window.location.pathname.match(elt.dataset.selectedRegexHref)) {
        elt.classList.add("selected");
      }
    });

    gsap.to(document.body, { scrollTop: 0 }); // For Safari
    gsap.to(document.documentElement, {
      scrollTop: 0,
    }); // For Chrome, Firefox, IE and Opera
  }

  @boundMethod
  onEnabled(){
    let scrollY = this.recoverScrollHash(window.location.hash) || 0;
    if(!this.recoverScrollHash(window.location.hash)){
      window.swup.scrollTo(scrollY, true);
      gsap.set(document.body, { scrollTop: scrollY }); // For Safari
      gsap.set(document.documentElement, {
        scrollTop: scrollY,
      }); // For Chrome, Firefox, IE and Opera
    }
    else{
      if(document.querySelector(`[data-controller="ui--horizontal-scrolltrigger"]`) && (window.innerWidth >= 1024)){
        setTimeout(() => {
          window.bodyScrollBar.scrollLeft = scrollY;
        }, 300);
      } else {
        gsap.to(document.body, { duration: 2, scrollTo: scrollY }); // For Safari
        gsap.to(document.documentElement, {
          duration: 2, scrollTo: scrollY
        }); // For Chrome, Firefox, IE and Opera
      }

    }
    this.onLocationChange();
  }

  @boundMethod
  onWillReplaceContent(visit){
    const enterLayout = visit.to.document?.documentElement.querySelector(`[data-controller="ui--horizontal-scrolltrigger"]`) ;
    let scrollY = visit?.state?.scrollState?.scrollY || 0;
    scrollY = (this.recoverScrollHash(window.location.hash)) ?  this.recoverScrollHash(window.location.hash) : scrollY;
    if( enterLayout  && window.innerWidth >= 1024){
        let scrollX = visit?.state?.scrollState?.scrollX || 0;
        scrollX = (this.recoverScrollHash(window.location.hash)) ?  this.recoverScrollHash(window.location.hash) : scrollX;
        setTimeout(()=> window.bodyScrollBar.scrollTo(scrollX, 0, 1000), 0);
    }else{
      window.scrollTo({
        top: scrollY,
        behavior: 'instant',
      });
    }

  }

  recoverScrollHash(hash) {
    if (!hash) return false;
    const hashEl = document.querySelector(hash);
    if (!hashEl) return false;
    if (document.querySelector(`[data-controller="ui--horizontal-scrolltrigger"]`) && (window.innerWidth >= 1024)) {
      return hashEl.offsetLeft;
    }
    return hashEl.offsetTop;
  }

  @boundMethod
  onHistoryBack(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    return window.history.back();
  }

}
