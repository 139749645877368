import Scrollbar, { ScrollbarPlugin } from 'smooth-scrollbar';

export default class HorizontalScrollPlugin extends ScrollbarPlugin {
  static pluginName = 'horizontalScroll';

  static defaultOptions = {
    enable: true,
    loaded: false
  };

  onInit() {
    this.options.loaded = true;
  }

  transformDelta(delta, fromEvent) {

    if (!/wheel/.test(fromEvent.type)) {
      return (this.options.enable) ? delta : {x:0, y:0};
    }

    const { x, y } = delta;

    if(this.options.enable){
      return {
        y: 0,
        x: Math.abs(x) > Math.abs(y) ? x : y,
      };
    }else{
      return {
        y: 0,
        x: 0,
      };
    }

  }
}
