import {IntersectionController} from "stimulus-use";
import {boundMethod} from 'autobind-decorator';
export default class extends IntersectionController {

  _introDelay = 0;
  _elementScroll = 0;
  _isScrollable = false;

  initialize() {
  }

  connect() {
    this.body = document.querySelector('body');
    this.element.addEventListener('mouseenter', this.onMouseEnterButton);
    this.element.addEventListener('mouseleave', this.onMouseLeaveButton);
    this.element.addEventListener('scroll', this.onScroll);
    window.addEventListener('resize', this.onResize);
    this.element.classList.add('inside-scroll');
    this.element.classList.toggle('inside-scroll', this.isScrollable());
  }

  @boundMethod
  onScroll(e){
    e.preventDefault();
    this.elementScroll = this.element.scrollTop;
  }

  @boundMethod
  onResize(){
    this.element.classList.toggle('inside-scroll', this.isScrollable());
  }

  set elementScroll(value){
    if(this._elementScroll !== value){
      this._elementScroll = value;
    }else{
      window.bodyScrollBar.updatePluginOptions('horizontalScroll', { enable: false });
    }
  }

  get elementScroll(){
    return this._elementScroll;
  }

  @boundMethod
  isScrollable(){
    const isScollable = this.element.scrollHeight > this.element.clientHeight;
    if(this._isScrollable !== isScollable) this._isScrollable = isScollable;
    return this._isScrollable;
  }

  @boundMethod
  onMouseEnterButton () {
    if(!this.isScrollable()) return;
    this.element.classList.add('inside-scroll');
    window.bodyScrollBar.updatePluginOptions('horizontalScroll', { enable: false });
  }

  @boundMethod
  onMouseLeaveButton () {
    if(!this.isScrollable()) return;
   this.element.classList.remove('inside-scroll');
   window.bodyScrollBar.updatePluginOptions('horizontalScroll', { enable: true });
  }


  disconnect() {
    this.element.removeEventListener('mouseenter', this.onMouseEnterButton);
    this.element.removeEventListener('mouseleave', this.onMouseLeaveButton);
    this.element.removeEventListener('scroll', this.onScroll);
  }

}
