import {Controller} from "@hotwired/stimulus";
import {boundMethod} from "autobind-decorator";
import {useMutation} from "stimulus-use";
import {gsap} from "gsap";

export default class extends Controller {
  static targets = ["close", "bg"];

  connect() {
    this.body = document.querySelector("body");
    this.page = document.querySelector("#swup > .page");
    this.leftbarre = document.querySelector(".leftbarre");


    this.menuBody = this.element.querySelector('.menu-body');
    this.menuBg = this.element.querySelector('.menu-fd');
    this.animating = false;

    // Animation elements
    this.menuItems = this.element.querySelectorAll(
      ".menu-item"
    );

    // Click -> closes menu
    this.menuLinks = this.element.querySelectorAll(
      '.menu-item[data-menu-target="item"], .item-ad'
    );
    this.menuLinks.forEach((menuLink) => {
      menuLink.addEventListener("click", this.onClick);
    });

    this.sociaux = this.element.querySelectorAll('.sociaux a');

    this.footer = this.element.querySelectorAll('.footer li');

    useMutation(this, {
      element: this.body,
      attributes: true,
      attributeFilter: ["class"],
    });

    document.addEventListener('swup:content:replace', this.onContentReplaced);
  }

  @boundMethod
  onContentReplaced(){
    this.page = window.swup.querySelector( ".page");
  }

  @boundMethod
  onClick(evt) {
    this.body.classList.toggle("menu-open");
  }

  @boundMethod
  mutate(entries) {
    if (this.body.classList.contains("menu-open")) {
      this.open();
    } else {
      this.close();
    }
  }

  @boundMethod
  initAnimations() {

    if (window.innerWidth > 600) {
      gsap.set([this.menuBody, this.menuBg], {
        x: '-95%',
      })
    } else {
      gsap.set([this.menuBody, this.menuBg], {
        y: '-95%',
      })
    }

    gsap.set(this.element, {
      opacity: 0,
      pointerEvents: "none",
    });
    gsap.set([this.menuItems, ...this.footer], {
      opacity: 0,
      pointerEvents: "none",
      y: 15,
    });
    gsap.set([this.sociaux], {
      opacity: 0,
      pointerEvents: "none",
      y: 15,
    });
  }

  @boundMethod
  open() {

    this.leftbarre.classList.add('menu-open');
    gsap.set(this.menuBody, {
      overflow: 'hidden',
    });
    this.initAnimations();

    let slideAnim;
    let pageAnim;
    if (window.innerWidth > 600) {
      slideAnim = gsap.to([this.menuBody, this.menuBg], {
        x: '0%',
        duration: 1,
        ease: 'power4.out',
        stagger: 0.15,
      });
      pageAnim = gsap.to(this.page, {
        x: '30%',
        duration: 0.8,
        ease: 'power4.out',
      });
    } else {
      slideAnim = gsap.to([this.menuBody, this.menuBg], {
        y: '0%',
        duration: 1,
        ease: 'power4.out',
        stagger: 0.15,
      });
      pageAnim = gsap.to(this.page, {
        y: '10%',
        duration: 0.8,
        ease: 'power4.out',
      });
    }

    const tweenElt = gsap.to(this.element, {
      opacity: 1,
      pointerEvents: "auto",
      duration: 0.5,
      ease: 'power4.out',
    });

    const tweenItems = gsap.to([this.menuItems, ...this.footer], {
      opacity: 1,
      pointerEvents: "auto",
      y: 0,
      duration: 0.6,
      stagger: 0.1,
      delay: 0.3,
    });

    const tweenSociaux = gsap.to([this.sociaux], {
      opacity: 1,
      pointerEvents: "auto",
      y: 0,
      duration: 0.6,
      delay: 0.3,
      stagger: function (index, target, list) {
        target.classList.add('appear');
        target.dispatchEvent(new CustomEvent("appear:el"));
        return index * 0.1;
      },
    });

    Promise.all([pageAnim, slideAnim, tweenElt, tweenItems, tweenSociaux]).then(() => {
      gsap.set(this.menuBody, {
        overflow: 'auto',
      });
    });
  }

  @boundMethod
  close() {
    this.leftbarre.classList.remove('menu-open');
    gsap.set(this.menuBody, {
      clearProps: 'all',
    });
    gsap.to([this.menuItems, ...this.footer], {
      opacity: 0,
      pointerEvents: "none",
    });
    gsap.to([this.sociaux], {
      opacity: 0,
      pointerEvents: "none",
      stagger: function (index, target, list) {
        target.classList.remove('appear');
        target.dispatchEvent(new CustomEvent("disappear:el"));
        return index * 0.1;
      },
    });
    if (window.innerWidth > 600) {
      gsap.to([this.menuBg, this.menuBody], {
        x: '-95%',
        duration: 0.4,
        ease: 'power2.in',
        stagger: 0.05,
      });
      gsap.to(this.page, {
        x: 0,
        duration: 0.5,
        ease: 'power2.in',
      });
    } else {
      gsap.to([this.menuBg, this.menuBody], {
        y: '-95%',
        duration: 0.4,
        ease: 'power2.in',
        stagger: 0.05,
      });
      gsap.to(this.page, {
        y: 0,
        x: 0,
        duration: 0.5,
        ease: 'power2.in',
      });
    }
    gsap.to(this.element, {
      opacity: 0,
      pointerEvents: "none",
      duration: 0.5,
      delay: 0.2
    });
  }

  disconnect() {
    this.menuLinks.forEach((menuLink) => {
      menuLink.removeEventListener("click", this.onClick);
    });
  }
}

