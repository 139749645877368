import { IntersectionController } from "stimulus-use";
import gsap from "gsap";
import { boundMethod } from "autobind-decorator";
import {isFirefox} from "../../libs/device-detect";

export default class extends IntersectionController {
  static values = {
    x: Number,
    y: Number,
    mobileX: Number,
    mobileY: Number,
    scaleX: Number,
    scaleY: Number,
    duration: Number,
    delay: Number,
    waitForIntroVideo: Boolean || false,
    rootMargin : String || "0px 0px 0px 0px",
    ease: String
  };

  options = {
    threshold: 0.2, // default
  };

  initialize() {
    super.initialize();
    this.options.rootMargin = this.rootMarginValue;
  }

  connect() {
    // ANIMATION SETUP
    if (
      window.innerWidth >= 868
      || (!this.mobileXValue && !this.mobileYValue)
    ) {
      this.x = this.xValue;
      this.y = this.yValue;
    } else {
      this.x = (this.mobileXValue) ?? 0;
      this.y = (this.mobileYValue) ?? 0;
    }
    gsap.set(this.element, {
      opacity: 0,
      x: this.x || 0,
      y: this.y || 0,
      scaleX: this.scaleXValue || 1,
      scaleY: this.scaleYValue || 1,
    });
  }
  disconnect() {}

  appear(entry) {
    if (!this.element.closest('.popin') || this.element.closest('.popin.popin-moved') ) {
      this.unobserve();
    }
    this.showElement();
  }

  @boundMethod
  showElement() {
    const _this = this;
    this.element.classList.add('appear');
    this.hackFirefoxForhasCss();
    const transformTween = gsap.to(this.element, {
      delay: this.delayValue || 0,
      duration: this.durationValue || 0.5,
      x: 0,
      y: 0,
      scaleX: 1,
      scaleY: 1,
      ease: this.easeValue || "power2.out",
    });
    const opacityTween = gsap.to(this.element, {
      opacity: 1,
      delay: this.delayValue + 0.2 || 0.2,
      duration: this.durationValue - 0.2 || 0.3,
    });

    Promise.all([transformTween,opacityTween]).then(() => {
      _this.element.classList.add('animation-finish');
    })
  }

  hackFirefoxForhasCss(){
    if(!isFirefox) return;
    const H1 = this.element.closest('h1');
    H1 && H1.classList.add('has-appear');
  }

}

