import { IntersectionController } from 'stimulus-use';
import gsap from 'gsap';
import {boundMethod} from "autobind-decorator";

export default class extends IntersectionController {
  static values = {
    duration: Number,
    threshold: Number,
    delay: Number
  };

  options = {
    threshold: 0, // default
  };

  initialize () {
    this.options.threshold = this.thresholdValue;
  }

  @boundMethod
  appear (entry) {

    if (!this.element.closest('.popin') || this.element.closest('.popin.popin-moved') ) {
      this.unobserve();
    }
    this.element.classList.add('appear');
    gsap.to(
      this.element,
      {
        ease: 'power2.in',
        opacity: 1,
        delay: this.delayValue || 0,
        duration: this.durationValue || 0.5,
      },
    );
  }

}
