import {useIntersection} from "stimulus-use";
import { boundMethod} from "autobind-decorator";
import { gsap } from 'gsap';
import {Controller} from "@hotwired/stimulus";

export default class extends Controller {

  moved = false;

  options = {
    threshold: 0, // 0 by default
  };

  initialize() {
    this.body = document.querySelector('body');
    this.id = this.element.id;
    if (this.id) {
      this.triggerPopin = this.body.querySelector(
        '.triggerPopin[data-popin="' + this.id + '"]',
      );
    }
    if (this.triggerPopin) {
      setTimeout(this.moveOnBody, 0);
      document.addEventListener('swup:content:replace', this.onRemove);
    } else {
    //  console.log("La popin n'a pas d'attribut data id ou pas de trigger");
    }
  }

  connect() {
    const [observeIntersection, unobserveIntersection] = useIntersection(
        this,
        this.optionsValue || {},
    );
    this.observeIntersection = () => observeIntersection();
    this.unobserveIntersection = () => unobserveIntersection();
    this.unobserveIntersection();
    if (this.triggerPopin) {
      this.popinContainer = this.element.querySelector('.popin-container');
      this.initEvents();
      gsap.to(this.triggerPopin, {
        opacity: 1,
        duration: 0.3,
      });
      this.triggerPopin.addEventListener('click', this.onClickTrigger);

    } else {
      this.element.remove();
    }
  }


  @boundMethod
  initEvents() {
    this.triggerPopin.addEventListener('click', this.onClickTrigger);
    this.popinContainer.addEventListener('click', this.onClickPopin);
    this.body.addEventListener('click', this.closePopin);
  }

  @boundMethod
  removeEvents() {
    this.triggerPopin.removeEventListener('click', this.onClickTrigger);
    this.popinContainer.removeEventListener('click', this.onClickPopin);
    this.body.removeEventListener('click', this.closePopin);
    this.element.classList.remove('popin-close');
    this.element.classList.remove('popin-show');
  }

  @boundMethod
  moveOnBody() {
    this.element.classList.add('popin-moved');
    this.body.appendChild(this.element);
    this.moved = true;
  }

  @boundMethod
  onRemove() {
    if(!this.moved) return;
    this.body.removeChild(this.element);
    this.moved = false;
  }

  @boundMethod
  onClickPopin(e) {
    e.stopPropagation();
  }

  @boundMethod
  onClickTrigger(e) {
    e.stopPropagation();
    e.preventDefault();
    this.element.classList.toggle('popin-show', this.toggleClass())
    this.observeIntersection();
  }

  toggleClass(){
    const bool = !this.element.classList.contains('popin-show');
    if(bool){
      this.appear();
    }
    else{
      this.disappear();
    }
    return bool;
  }

  @boundMethod
  appear() {
    this.isVisible = true;
  }

  @boundMethod
  disappear(){
    this.isVisible = false;
    this.closePopin();
  }

  @boundMethod
  closePopin() {
    if(!this.element.classList.contains('popin-show')) return;
    this.element.classList.add('popin-close');
    this.element.classList.remove('popin-show');
    setTimeout(()=> {
      this.element.classList.remove('popin-close');
    }, 800);
  }

  disconnect() {
    this.triggerPopin && this.removeEvents();
    super.disconnect();
  }

}
