import { IntersectionController } from 'stimulus-use';
import {boundMethod} from "autobind-decorator";

export default class extends IntersectionController {
  static values = {
    duration: Number,
    threshold: Number,
    delay: Number,
    rootMargin: "0px 0px 0px 0px"
  };

  options = {
    threshold: 0, // default
  };

  initialize () {
    this.options.threshold = this.thresholdValue;
    this.options.rootMargin = this.rootMarginValue;
  }

  @boundMethod
  appear (entry) {
    if (!this.element.closest('.popin') || this.element.closest('.popin.popin-moved') ) {
      this.unobserve();
    }
    this.element.dispatchEvent(new CustomEvent('appear_controller:appear', {detail: this.element}));
  }

}
