import { Controller } from "@hotwired/stimulus";
import { gsap } from "gsap";
import { boundMethod } from "autobind-decorator";

export default class extends Controller {
  static targets = ["container" , "btn", "item", 'select'];

  connect() {
    this.open = false;
    this.timeline = gsap.timeline();
    this.clipStart = "polygon(0 0, 100% 0, 100% 0%, 0 0%)";
    this.clipEnd = "polygon(0 0, 105% 0, 105% 105%, 0 105%)";

    //? Close dropdown on click outside
    document.addEventListener("click", this.onClickBody);

    this.initAnimDropdownItems();
  }
  disconnect() {
    document.removeEventListener("click", this.onClickBody);
  }

  @boundMethod
  onClickBody(event) {
    !this.element.contains(event.target) && this.open
      ? this.closeDropdown()
      : null;
  }

  // Dropdown Container
  toggleDropdown() {
    this.open = !this.open;
    this.open ? this.openDropdown() : this.closeDropdown();
  }
  openDropdown() {
    this.open = true;
    this.selectTarget.click();
    this.timeline.fromTo(
      this.containerTarget,
      {
        clipPath: this.clipStart,
      },
      {
        clipPath: this.clipEnd,
        boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.15)",
        opacity: 1,
        duration: 0.5,
        pointerEvents: "auto",
        onStart: () => {
          this.animDropdownItems();
        },
      }
    );
  }
  closeDropdown() {
    // console.log("dropdown CLOSED");
    this.open = false;
    this.timeline.to(
      this.containerTarget,
      {
        clipPath: this.clipStart,
        opacity: 0,
        pointerEvents: "none",
        duration: 0.4,
      }
    );
  }

  initAnimDropdownItems() {
    gsap.set([...this.itemTargets], { opacity: 0 });
    gsap.set(
      this.element.querySelectorAll(".simple-dropdown-item-separator"),
      {
        width: "0%",
        opacity: 0,
      }
    );
  }
  animDropdownItems() {
    gsap.to([...this.itemTargets], {
      opacity: 1,
      stagger: 0.2,
      duration: 1.5,
    });
    gsap.to(
      this.element.querySelectorAll(".simple-dropdown-item-separator"),
      {
        width: "75%",
        opacity: 1,
        stagger: 0.2,
        duration: 1.5,
      }
    );
  }

@boundMethod
selectItem(e){
    const id = e.currentTarget.dataset.id;
    if(id !== this.selectTarget.value){
      this.btnTarget.querySelector('.simple-dropdown-btn-label ').innerText = e.currentTarget.innerText;
      this.selectTarget.querySelectorAll('option').forEach(o => o.value === id ? o.value = id : '');
      this.selectTarget.value = id;
      this.selectTarget.dispatchEvent(new Event('change'));
    }
    this.closeDropdown()
  }
}
