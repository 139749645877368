

Object.defineProperty(exports, '__esModule', { value: true });

const UAParser = require('ua-parser-js/dist/ua-parser.min');

const UA = new UAParser();
const browser = UA.getBrowser();
const cpu = UA.getCPU();
const device = UA.getDevice();
const engine = UA.getEngine();
const os = UA.getOS();
const ua = UA.getUA();

const setDefaults = function setDefaults(p) {
  const d = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'none';
  return p || d;
};
const getNavigatorInstance = function getNavigatorInstance() {
  if (typeof window !== 'undefined') {
    if (window.navigator || navigator) {
      return window.navigator || navigator;
    }
  }

  return false;
};
const isIOS13Check = function isIOS13Check(type) {
  const nav = getNavigatorInstance();
  return nav && nav.platform && (nav.platform.indexOf(type) !== -1 || nav.platform === 'MacIntel' && nav.maxTouchPoints > 1 && !window.MSStream);
};

function _typeof(obj) {
  if (typeof Symbol === 'function' && typeof Symbol.iterator === 'symbol') {
    _typeof = function (obj) {
      return typeof obj;
    };
  } else {
    _typeof = function (obj) {
      return obj && typeof Symbol === 'function' && obj.constructor === Symbol && obj !== Symbol.prototype ? 'symbol' : typeof obj;
    };
  }

  return _typeof(obj);
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _defineProperties(target, props) {
  for (let i = 0; i < props.length; i++) {
    const descriptor = props[i];
    descriptor.enumerable = descriptor.enumerable || false;
    descriptor.configurable = true;
    if ('value' in descriptor) descriptor.writable = true;
    Object.defineProperty(target, descriptor.key, descriptor);
  }
}

function _createClass(Constructor, protoProps, staticProps) {
  if (protoProps) _defineProperties(Constructor.prototype, protoProps);
  if (staticProps) _defineProperties(Constructor, staticProps);
  return Constructor;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value,
      enumerable: true,
      configurable: true,
      writable: true,
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (let i = 1; i < arguments.length; i++) {
      const source = arguments[i];

      for (const key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function ownKeys(object, enumerableOnly) {
  const keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    let symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) {
      symbols = symbols.filter(sym => Object.getOwnPropertyDescriptor(object, sym).enumerable);
    }
    keys.push(...symbols);
  }

  return keys;
}

function _objectSpread2(target) {
  for (let i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(source, true).forEach((key) => {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(source).forEach((key) => {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError('Super expression must either be null or a function');
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      writable: true,
      configurable: true,
    },
  });
  if (superClass) _setPrototypeOf(subClass, superClass);
}

function _getPrototypeOf(o) {
  _getPrototypeOf = Object.setPrototypeOf ? Object.getPrototypeOf : function _getPrototypeOf(o) {
    return o.__proto__ || Object.getPrototypeOf(o);
  };
  return _getPrototypeOf(o);
}

function _setPrototypeOf(o, p) {
  _setPrototypeOf = Object.setPrototypeOf || function _setPrototypeOf(o, p) {
    o.__proto__ = p;
    return o;
  };

  return _setPrototypeOf(o, p);
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  const target = {};
  const sourceKeys = Object.keys(source);
  let key,
    i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  const target = _objectWithoutPropertiesLoose(source, excluded);

  let key,
    i;

  if (Object.getOwnPropertySymbols) {
    const sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _assertThisInitialized(self) {
  if (self === void 0) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return self;
}

function _possibleConstructorReturn(self, call) {
  if (call && (typeof call === 'object' || typeof call === 'function')) {
    return call;
  }

  return _assertThisInitialized(self);
}

const DeviceTypes = {
  Mobile: 'mobile',
  Tablet: 'tablet',
  SmartTv: 'smarttv',
  Console: 'console',
  Wearable: 'wearable',
  Browser: undefined,
};
const BrowserTypes = {
  Chrome: 'Chrome',
  Firefox: 'Firefox',
  Opera: 'Opera',
  Yandex: 'Yandex',
  Safari: 'Safari',
  InternetExplorer: 'Internet Explorer',
  Edge: 'Edge',
  Chromium: 'Chromium',
  Ie: 'IE',
  MobileSafari: 'Mobile Safari',
  EdgeChromium: 'Edge Chromium',
  MIUI: 'MIUI Browser',
  SamsungBrowser: 'Samsung Browser',
};
const OsTypes = {
  IOS: 'iOS',
  Android: 'Android',
  WindowsPhone: 'Windows Phone',
  Windows: 'Windows',
  MAC_OS: 'Mac OS',
};
const initialData = {
  isMobile: false,
  isTablet: false,
  isBrowser: false,
  isSmartTV: false,
  isConsole: false,
  isWearable: false,
};
const checkType = function checkType(type) {
  switch (type) {
    case DeviceTypes.Mobile:
      return {
        isMobile: true,
      };

    case DeviceTypes.Tablet:
      return {
        isTablet: true,
      };

    case DeviceTypes.SmartTv:
      return {
        isSmartTV: true,
      };

    case DeviceTypes.Console:
      return {
        isConsole: true,
      };

    case DeviceTypes.Wearable:
      return {
        isWearable: true,
      };

    case DeviceTypes.Browser:
      return {
        isBrowser: true,
      };

    default:
      return initialData;
  }
};
const broPayload = function broPayload(isBrowser, browser, engine, os, ua) {
  return {
    isBrowser,
    browserMajorVersion: setDefaults(browser.major),
    browserFullVersion: setDefaults(browser.version),
    browserName: setDefaults(browser.name),
    engineName: setDefaults(engine.name),
    engineVersion: setDefaults(engine.version),
    osName: setDefaults(os.name),
    osVersion: setDefaults(os.version),
    userAgent: setDefaults(ua),
  };
};
const mobilePayload = function mobilePayload(type, device, os, ua) {
  return _objectSpread2({}, type, {
    vendor: setDefaults(device.vendor),
    model: setDefaults(device.model),
    os: setDefaults(os.name),
    osVersion: setDefaults(os.version),
    ua: setDefaults(ua),
  });
};
const stvPayload = function stvPayload(isSmartTV, engine, os, ua) {
  return {
    isSmartTV,
    engineName: setDefaults(engine.name),
    engineVersion: setDefaults(engine.version),
    osName: setDefaults(os.name),
    osVersion: setDefaults(os.version),
    userAgent: setDefaults(ua),
  };
};
const consolePayload = function consolePayload(isConsole, engine, os, ua) {
  return {
    isConsole,
    engineName: setDefaults(engine.name),
    engineVersion: setDefaults(engine.version),
    osName: setDefaults(os.name),
    osVersion: setDefaults(os.version),
    userAgent: setDefaults(ua),
  };
};
const wearPayload = function wearPayload(isWearable, engine, os, ua) {
  return {
    isWearable,
    engineName: setDefaults(engine.name),
    engineVersion: setDefaults(engine.version),
    osName: setDefaults(os.name),
    osVersion: setDefaults(os.version),
    userAgent: setDefaults(ua),
  };
};

const type = checkType(device.type);

function deviceDetect() {
  let isBrowser = type.isBrowser,
    isMobile = type.isMobile,
    isTablet = type.isTablet,
    isSmartTV = type.isSmartTV,
    isConsole = type.isConsole,
    isWearable = type.isWearable;

  if (isBrowser) {
    return broPayload(isBrowser, browser, engine, os, ua);
  }

  if (isSmartTV) {
    return stvPayload(isSmartTV, engine, os, ua);
  }

  if (isConsole) {
    return consolePayload(isConsole, engine, os, ua);
  }

  if (isMobile) {
    return mobilePayload(type, device, os, ua);
  }

  if (isTablet) {
    return mobilePayload(type, device, os, ua);
  }

  if (isWearable) {
    return wearPayload(isWearable, engine, os, ua);
  }
}

const isMobileType = function isMobileType() {
  return device.type === DeviceTypes.Mobile;
};

const isTabletType = function isTabletType() {
  return device.type === DeviceTypes.Tablet;
};

const isMobileAndTabletType = function isMobileAndTabletType() {
  switch (device.type) {
    case DeviceTypes.Mobile:
    case DeviceTypes.Tablet:
      return true;

    default:
      return false;
  }
};

const isEdgeChromiumType = function isEdgeChromiumType() {
  return typeof ua === 'string' && ua.indexOf('Edg/') !== -1;
};

const isSmartTVType = function isSmartTVType() {
  return device.type === DeviceTypes.SmartTv;
};

const isBrowserType = function isBrowserType() {
  return device.type === DeviceTypes.Browser;
};

const isWearableType = function isWearableType() {
  return device.type === DeviceTypes.Wearable;
};

const isConsoleType = function isConsoleType() {
  return device.type === DeviceTypes.Console;
};

const isAndroidType = function isAndroidType() {
  return os.name === OsTypes.Android;
};

const isWindowsType = function isWindowsType() {
  return os.name === OsTypes.Windows;
};

const isMacOsType = function isMacOsType() {
  return os.name === OsTypes.MAC_OS;
};

const isWinPhoneType = function isWinPhoneType() {
  return os.name === OsTypes.WindowsPhone;
};

const isIOSType = function isIOSType() {
  return os.name === OsTypes.IOS;
};

const isChromeType = function isChromeType() {
  return browser.name === BrowserTypes.Chrome;
};

const isFirefoxType = function isFirefoxType() {
  return browser.name === BrowserTypes.Firefox;
};

const isChromiumType = function isChromiumType() {
  return browser.name === BrowserTypes.Chromium;
};

const isEdgeType = function isEdgeType() {
  return browser.name === BrowserTypes.Edge;
};

const isYandexType = function isYandexType() {
  return browser.name === BrowserTypes.Yandex;
};

const isSafariType = function isSafariType() {
  return browser.name === BrowserTypes.Safari || browser.name === BrowserTypes.MobileSafari;
};

const isMobileSafariType = function isMobileSafariType() {
  return browser.name === BrowserTypes.MobileSafari;
};

const isOperaType = function isOperaType() {
  return browser.name === BrowserTypes.Opera;
};

const isIEType = function isIEType() {
  return browser.name === BrowserTypes.InternetExplorer || browser.name === BrowserTypes.Ie;
};

const isMIUIType = function isMIUIType() {
  return browser.name === BrowserTypes.MIUI;
};

const isSamsungBrowserType = function isSamsungBrowserType() {
  return browser.name === BrowserTypes.SamsungBrowser;
};

const isElectronType = function isElectronType() {
  const nav = getNavigatorInstance();
  const ua = nav && nav.userAgent && nav.userAgent.toLowerCase();
  return typeof ua === 'string' ? /electron/.test(ua) : false;
};

const getIOS13 = function getIOS13() {
  const nav = getNavigatorInstance();
  return nav && (/iPad|iPhone|iPod/.test(nav.platform) || nav.platform === 'MacIntel' && nav.maxTouchPoints > 1) && !window.MSStream;
};

const getIPad13 = function getIPad13() {
  return isIOS13Check('iPad');
};

const getIphone13 = function getIphone13() {
  return isIOS13Check('iPhone');
};

const getIPod13 = function getIPod13() {
  return isIOS13Check('iPod');
};

const getBrowserFullVersion = function getBrowserFullVersion() {
  return setDefaults(browser.version);
};

const getBrowserVersion = function getBrowserVersion() {
  return setDefaults(browser.major);
};

const getOsVersion = function getOsVersion() {
  return setDefaults(os.version);
};

const getOsName = function getOsName() {
  return setDefaults(os.name);
};

const getBrowserName = function getBrowserName() {
  return setDefaults(browser.name);
};

const getMobileVendor = function getMobileVendor() {
  return setDefaults(device.vendor);
};

const getMobileModel = function getMobileModel() {
  return setDefaults(device.model);
};

const getEngineName = function getEngineName() {
  return setDefaults(engine.name);
};

const getEngineVersion = function getEngineVersion() {
  return setDefaults(engine.version);
};

const getUseragent = function getUseragent() {
  return setDefaults(ua);
};

const getDeviceType = function getDeviceType() {
  return setDefaults(device.type, 'browser');
};

const isSmartTV = isSmartTVType();
const isConsole = isConsoleType();
const isWearable = isWearableType();
const isMobileSafari = isMobileSafariType() || getIPad13();
const isChromium = isChromiumType();
const isMobile = isMobileAndTabletType() || getIPad13();
const isMobileOnly = isMobileType();
const isTablet = isTabletType() || getIPad13();
const isBrowser = isBrowserType();
const isDesktop = isBrowserType();
const isAndroid = isAndroidType();
const isWinPhone = isWinPhoneType();
const isIOS = isIOSType() || getIPad13();
const isChrome = isChromeType();
const isFirefox = isFirefoxType();
const isSafari = isSafariType();
const isOpera = isOperaType();
const isIE = isIEType();
const osVersion = getOsVersion();
const osName = getOsName();
const fullBrowserVersion = getBrowserFullVersion();
const browserVersion = getBrowserVersion();
const browserName = getBrowserName();
const mobileVendor = getMobileVendor();
const mobileModel = getMobileModel();
const engineName = getEngineName();
const engineVersion = getEngineVersion();
const getUA = getUseragent();
const isEdge = isEdgeType() || isEdgeChromiumType();
const isYandex = isYandexType();
const deviceType = getDeviceType();
const isIOS13 = getIOS13();
const isIPad13 = getIPad13();
const isIPhone13 = getIphone13();
const isIPod13 = getIPod13();
const isElectron = isElectronType();
const isEdgeChromium = isEdgeChromiumType();
const isLegacyEdge = isEdgeType() && !isEdgeChromiumType();
const isWindows = isWindowsType();
const isMacOs = isMacOsType();
const isMIUI = isMIUIType();
const isSamsungBrowser = isSamsungBrowserType();


exports.browserName = browserName;
exports.browserVersion = browserVersion;
exports.deviceDetect = deviceDetect;
exports.deviceType = deviceType;
exports.engineName = engineName;
exports.engineVersion = engineVersion;
exports.fullBrowserVersion = fullBrowserVersion;
exports.getUA = getUA;
exports.isAndroid = isAndroid;
exports.isBrowser = isBrowser;
exports.isChrome = isChrome;
exports.isChromium = isChromium;
exports.isConsole = isConsole;
exports.isDesktop = isDesktop;
exports.isEdge = isEdge;
exports.isEdgeChromium = isEdgeChromium;
exports.isElectron = isElectron;
exports.isFirefox = isFirefox;
exports.isIE = isIE;
exports.isIOS = isIOS;
exports.isIOS13 = isIOS13;
exports.isIPad13 = isIPad13;
exports.isIPhone13 = isIPhone13;
exports.isIPod13 = isIPod13;
exports.isLegacyEdge = isLegacyEdge;
exports.isMIUI = isMIUI;
exports.isMacOs = isMacOs;
exports.isMobile = isMobile;
exports.isMobileOnly = isMobileOnly;
exports.isMobileSafari = isMobileSafari;
exports.isOpera = isOpera;
exports.isSafari = isSafari;
exports.isSamsungBrowser = isSamsungBrowser;
exports.isSmartTV = isSmartTV;
exports.isTablet = isTablet;
exports.isWearable = isWearable;
exports.isWinPhone = isWinPhone;
exports.isWindows = isWindows;
exports.isYandex = isYandex;
exports.mobileModel = mobileModel;
exports.mobileVendor = mobileVendor;
exports.osName = osName;
exports.osVersion = osVersion;
