
import { app } from '../js/bootstrap';
import AppController from './controllers/app_controller';

import './styles/index.scss';

import './img/geoloc.svg';
import './img/store_category_1.svg';
import './img/store_category_1_hover.svg';
import './img/store_category_1_marker.svg';
import './img/store_category_2.svg';
import './img/store_category_2_hover.svg';
import './img/store_category_2_marker.svg';
import './img/store_category_3.svg';
import './img/store_category_3_hover.svg';
import './img/store_category_3_marker.svg';
import './img/store_category_4.svg';
import './img/store_category_4_hover.svg';
import './img/store_category_4_marker.svg';
import './img/store_category_5.svg';
import './img/store_category_5_hover.svg';
import './img/store_category_5_marker.svg';
import './img/store_category_6.svg';
import './img/store_category_6_hover.svg';
import './img/store_category_6_marker.svg';

app.register('vinium-sylius-store-locator-plugin--app', AppController);
