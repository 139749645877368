import { IntersectionController} from "stimulus-use";
import { boundMethod } from "autobind-decorator";
import {gsap} from "gsap";

export default class extends IntersectionController {
  connect() {
    super.connect();
    this.body = document.querySelector("body");
    this.burger_bar = this.element.querySelectorAll('span.bg-white');
  }
  // @boundMethod
  // onScroll(){
  //   this.element.classList.toggle('dark', (this.body.classList.contains('fiche-vin') && document.scrollingElement.scrollTop > (window.innerHeight - this.element.offsetTop - this.element.getBoundingClientRect().height/2)));
  // }

  @boundMethod
  appear() {
   // this.scrollEvent = window.addEventListener('scroll', this.onScroll);
    this.element.dispatchEvent(new CustomEvent("appear:el"));
    gsap.to(this.element, {
      opacity: 1,
      pointerEvents: "auto",
      duration: 0.5,
    });
    gsap.to(this.burger_bar, {
      opacity: 1,
      duration: 0.5,
      stagger:0.2,
      delay:0.2
    })
    gsap.from(this.burger_bar, {
      scaleX: 0,
      duration: 0.5,
      stagger:0.2,
      delay:0.2
    })
      .then(()=>{
        this.burger_bar.forEach(el => {
          gsap.set(el,{clearProps:"transform, opacity"})
          el.classList.add('transition-all');
          el.classList.add('duration-700');
          el.classList.add('opacity-1');
        });
      });
    this.unobserve();
  }

  disappear(){
   // window.removeEventListener('scroll', this.onScroll);
   // this.element.classList.remove('dark');
  }

  @boundMethod
  onClick(evt) {
    // console.log("MenuToggleController onClick");
    this.body.classList.toggle("menu-open");
  }

  disconnect() {
    super.disconnect();
  //  this.scrollEvent && window.removeEventListener('scroll', this.onScroll);
  //  this.element.classList.remove('dark');
  }
}
